:root {
    --theme-color-1: white;
    --theme-color-2: black;
    --theme-card-1: #64607D;
}

html {
    scroll-behavior: smooth;
}
body {
    background: var(--theme-color-1);
    color: var(--theme-color-2);
}
.landing {
    max-width: 1200px;
    margin: auto;
    padding: 15px;
}
.header-wrapper{
    background: var(--theme-color-2);
    position: sticky;
    top: 0px;
    z-index: 11;
}
.header {
    max-width: 1200px;
    margin: auto;
    padding: 15px;
    display: flex;
    position: sticky;
    top: -1px;
    color: var(--theme-color-1);
    background: var(--theme-color-2);
    z-index: 11;
    padding: 15px 0px;
}
.header .logo {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    line-height: 45px;
}
.header .c-menu {
    flex: 1;
    display: flex;
}
.header .c-menu .c-menu-wrapper {
    text-align: center;
    margin: auto;
}
.header .c-menu .c-menu-wrapper .menu-items {
    display: flex;
}
.header .c-menu .c-menu-wrapper .menu-items .menu-item {
    font-size: 18px;
    line-height: 25px;
    padding: 0px 15px;
    cursor: pointer;
}

.header .r-menu {
    display: flex;
}
.header .r-menu .r-menu-wrapper {
    text-align: center;
    margin: auto;
}

.header .r-menu .r-menu-wrapper .menu-items {
    display: flex;
}

.header .r-menu .r-menu-wrapper .menu-items .menu-item {
    font-size: 18px;
    line-height: 25px;
    padding: 0px 15px;
    cursor: pointer;
    
}
.header .r-menu .r-menu-wrapper .menu-items .menu-item.faq {
    line-height: 45px;
}
.header .r-menu .r-menu-wrapper .menu-items .menu-item .btn.download {
    background: #E03131;
    border-radius: 10px;
    padding: 10px 25px;
}
.header .mobile-only{
    display: none;
}
.header .mobile-menu {
    position: fixed;
    top: 75px;
    right: 0px;
    left: 0px;
    background-color: var(--theme-color-2);
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 2.5s linear;
}

.header .mobile-menu.active {
    visibility: visible;
    opacity: 1;
}

.header .mobile-menu .menu-item {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: var(--theme-color-2);
    border: 1px solid var(--theme-color-2);
    font-weight: 400;
    font-size: 18.66px;
    border-bottom: 1px solid lightgray;
}
.header .mobile-menu .menu-item:last-child {
    border-bottom: 1px solid var(--theme-color-2);
}

@media (max-width: 700px) {
    .header .logo {
        padding-left: 15px;
        flex: 1;
    }
    .header .c-menu,
    .header .r-menu .r-menu-wrapper .menu-items .menu-item.faq,
    .header .r-menu .r-menu-wrapper .menu-items .menu-item .btn.download {
        display: none;
    }
    .header .mobile-only {
        display: unset;
    }
}

/* ******************************************************** */
/* home-section */
.landing .home-section {
    display: flex;
    margin-top: 70px;
}
.landing .home-section .left {
    width: 60%;
    padding-right: 15px;
    padding-top: 30px;
}
.landing .home-section .right {
    width: 40%;
}
.landing .home-section .right img {
    width: 100%;
}
.landing .home-section .left .sub-title-top {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
}
.landing .home-section .left .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 76px;
    letter-spacing: -1.8px;
    color: #1A1A1A;
}
.landing .home-section .left .title .red {
    color: #E03131;
}
.landing .home-section .left .sub-title-bottom {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #828282;
    max-width: 550px;
}
.landing .home-section .left .header-download-section {
    margin-top: 50px;
    display: flex;
}
.landing .home-section .left .header-download-section .header-download-buttons {
    display: flex;
    gap: 25px;
}
.landing .home-section .left .header-download-section .header-download-buttons .btn {
    cursor: pointer;
}
.landing .home-section .left .header-download-section .header-download-buttons .btn.download:last-child {
    margin-top: 28px;
}
.landing .home-section .left .header-download-section .header-check-list {
    width: 200px;
}
.landing .home-section .left .header-download-section .header-check-list .list-item {
    display: flex;
}
.landing .home-section .left .header-download-section .header-check-list .list-item .text {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #757095;
    display: block;
}
.landing .home-section .left .header-download-section .header-check-list .list-item img {
    padding-right: 10px;
}
.landing .home-section .left .header-download-section .header-check-list .list-item:last-child {
    margin-top: 10px;
}
@media (max-width: 900px) {
    .landing .home-section {
        flex-direction: column-reverse;
    }
    .landing .home-section .left {
        width: 100%;
        padding-right: 0px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .landing .home-section .right {
        width: 100%;
    }
    .landing .home-section .right img{
        width: 80%;
        margin: auto;
        display: block;
    }
}
@media (max-width: 500px) {
    .landing .home-section .left .header-download-section {
        display: block;
    }
    .landing .home-section .left .header-download-section .header-check-list {
        margin-top: 20px;
    }
    .landing .home-section .left .header-download-section .header-download-buttons {
        display: grid;
    }
}


/* ******************************************************** */
/* how-it-work-section */

.landing .how-it-work-section {
    margin-top: 60px;
}
.landing .how-it-work-section .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #E03131;
}
.landing .how-it-work-section .steps {
    position: relative;
    
}
.landing .how-it-work-section .step-wrapper {
    position: absolute;
    top: 15px;
}
.landing .how-it-work-section .steps img {
    width: 100%;
}
.landing .how-it-work-section .step-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    letter-spacing: -1.2px;
    color: #1A1A1A;
}
.landing .how-it-work-section .step-details {
    margin-top: 15px;
    max-width: 357px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: #828282;
}
@media (max-width: 900px) {
    .landing .how-it-work-section .steps img {
        margin-top: 100px;
    }
}
@media (max-width: 600px) {
    .landing .how-it-work-section{
        margin-top: 20px;
    }
    .landing .how-it-work-section .step-details {
        max-width: 100%;
    }
    .landing .how-it-work-section .steps img {
        margin-top: 180px;
    }
}
@media (max-width: 500px) {
    .landing .how-it-work-section .step-details {
        max-width: 100%;
    }

    .landing .how-it-work-section .steps img {
        margin-top: 200px;
    }
}

/* ******************************************************** */
/* download-section */

.landing .download-section {
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    margin-top: 100px;
}
.landing .download-section .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--theme-color-1);
    margin-bottom: 28px;
}
.landing .download-section .sub-title {
    padding-top: 56px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #D0D0D0;
}
.landing .download-section .download-btn-wrappers {
    display: flex;
}
.landing .download-section .download-btn-wrappers .btn-icon {
    flex: 1;
    margin-bottom: 80px;
}
.landing .download-section .download-btn-wrappers .btn-icon img {
    width: 178px;
    margin-left: auto;
    margin-right: 12.5px;
    display: block;
    cursor: pointer;
}
.landing .download-section .download-btn-wrappers .btn-icon:last-child img {
    margin-right: auto;
    margin-left: 12.5px;
}
.landing .download-section img {
    width: 100%;
}

@media (max-width: 1200px) {
    .landing .download-section .title {
        padding-top: 40px;
    }
    .landing .download-section .sub-title {
        margin-bottom: 20px;
        padding-top: 16px;
    }
}
@media (max-width: 1100px) {
    .landing .download-section .title {
        padding-top: 30px;
    }
    .landing .download-section .sub-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
@media (max-width: 1000px) {
    .landing .download-section .title {
        padding-top: 30px;
        font-size: 35px;
        line-height: 40px;
    }
    .landing .download-section .sub-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon img {
        width: 150px;
    }
}
@media (max-width: 900px) {
    .landing .download-section .title {
        padding-top: 30px;
        font-size: 32px;
        line-height: 42px;
    }
    .landing .download-section .sub-title {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon img {
        width: 130px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon img {
        margin-right: 15px
    }
    .landing .download-section .download-btn-wrappers .btn-icon:last-child img {
        margin-left: 15px
    }
}

@media (max-width: 800px) {
    .landing .download-section .title {
        padding-top: 15px;
        font-size: 28px;
        line-height: 38px;
    }
    .landing .download-section .sub-title {
        font-size: 16px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon img {
        margin-right: 12px
    }

    .landing .download-section .download-btn-wrappers .btn-icon:last-child img {
        margin-left: 12px
    }
}
@media (max-width: 700px) {
    .landing .download-section {
        background: var(--theme-color-2);
    }
    .landing .download-section .download-btn-wrappers .btn-icon {
        margin-bottom: 40px;
    }
}
@media (max-width: 370px) {
    .landing .download-section .download-btn-wrappers {
        display: block;
        padding-bottom: 15px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon {
        margin-bottom: 15px;
    }
    .landing .download-section .download-btn-wrappers .btn-icon img,
    .landing .download-section .download-btn-wrappers .btn-icon:last-child img {
        margin: auto;
    }
}

/* ******************************************************** */
/* pricing-section */

.landing .pricing-section {
    margin-top: 40px;
}
.landing .pricing-section .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
}

.landing .pricing-section .card-wrapper {
    display: flex;
    margin-top: 100px;
}
.landing .pricing-section .card-wrapper .card {
    flex: 1;
}
.landing .pricing-section .card-wrapper .card .card-item {
    max-width: 430px;
    background: #F3F3F3;
    border-radius: 12px;
    color: var(--theme-color-1);
    background: var(--theme-color-2);
    margin: auto;
}
.landing .pricing-section .card-wrapper .card .card-item .title-wrapper {
    padding-top: 25px;
}
.landing .pricing-section .card-wrapper .card .card-item .title {
    background: #D9D9D9;
    border-radius: 50px;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.03em;
    color: var(--theme-color-2);
    display: block;
    margin: auto;
    width: 226px;
}
.landing .pricing-section .card-wrapper .card .card-item .note-items {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-top: 50px;
}
.landing .pricing-section .card-wrapper .card .card-item .note-items .note-item {
    padding: 7px;
}
.landing .pricing-section .card-wrapper .card .card-item .price {
    font-weight: 700;
    font-size: 80px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    padding-bottom: 55px;
    padding-top: 65px;
}
.landing .price-card-wrapper {
    background: #C94444;
    border-radius: 16px;
    margin-top: 191px;
    margin-bottom: 182px;
    background-size: cover;
    background-repeat: no-repeat;
}
.landing .price-card-wrapper .title {
    padding-top: 48px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.2px;
    color: #FFFFFF;
}
.landing .price-card-wrapper .price {
    padding-top: 40px;
    font-weight: 700;
    font-size: 80px;
    line-height: 78px;
    text-align: center;
    letter-spacing: -1.8px;
    color: #FFFFFF;
}
.landing .price-card-wrapper .price-sub-title {
    padding-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #FFFFFF;
}
.landing .price-card-wrapper .price-checks {
    display: flex;
    padding-top: 36px;
    padding-bottom: 50px;
    gap: 15px;
    max-width: 800px;
    margin: auto;
}
.landing .price-card-wrapper .price-checks .check-item-wrapper {
    flex: 1;
    text-align: center;
    
}
.landing .price-card-wrapper .price-checks .check-item {
    display: inline-flex;
    gap: 10px;
}
.landing .price-card-wrapper .price-checks .check-item .icon {
    vertical-align: middle;
    display: flex;
}
.landing .price-card-wrapper .price-checks .check-item .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    text-align: center;
    letter-spacing: -0.4px;
    color: #FFFFFF;
}
.landing .pricing-section .card-wrapper .card:last-child .card-item {
    background: #C93B3A;
    color: var(--theme-color-1);
}
.landing .pricing-section .card-wrapper .card:last-child .card-item .title {
    background: #DB8D8D;
    color: var(--theme-color-1);
}
.landing .pricing-section .card-wrapper .card:last-child .card-item .note-items,
.landing .pricing-section .card-wrapper .card:last-child .card-item .price { 
    color: var(--theme-color-1);
}

@media (max-width: 1100px) {
    .landing .pricing-section .card-wrapper .card .card-item {
        max-width: 380px;
    }
}
@media (max-width: 900px) {
    .landing .pricing-section .card-wrapper .card .card-item {
        max-width: 340px;
    }
}
@media (max-width: 800px) {
    .landing .pricing-section .card-wrapper .card .card-item {
        max-width: 300px;
    }
}
@media (max-width: 700px) {
    .landing .pricing-section .card-wrapper {
        display: block;
        margin-top: 60px;
    }
    .landing .pricing-section .card-wrapper .card .card-item {
        max-width: 80%;
    }
    .landing .pricing-section .card-wrapper .card:last-child {
        margin-top: 30px;
    }
    .landing .price-card-wrapper .price-checks {
        display: grid;
    }
}
@media (max-width: 500px) {
    .landing .pricing-section .card-wrapper .card {
        margin: 0px 15px;
    }
    .landing .pricing-section .card-wrapper .card .card-item {
        max-width: 100%;
    }
    
}

/* ******************************************************** */
/* faq-section */

.landing .faq-section {
    margin-top: 160px;
    margin-bottom: 200px;
}
.landing .faq-section .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: var(--theme-color-2);
    margin-bottom: 34px;
}
.landing .faq-section .qna {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: var(--theme-color-2);
    display: block;
    width: 80%;
}
.landing .faq-section .qna svg {
    margin-top: 4px;
}
.landing .faq-section .qna .que {
    /* margin-bottom: 20px; */
    display: flex;
    cursor: pointer;
}
.landing .faq-section .qna .ans {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: max-height .6s linear;
    -moz-transition: max-height .6s linear;
    -ms-transition: max-height .6s linear;
    -o-transition: max-height .6s linear;
    transition: max-height .6s linear;
}
.landing .faq-section .qna .ans.open {
    max-height: 300px;
    
}
@media (max-width: 800px) {
    .landing .faq-section {
        margin-top: 100px;
    }
    .landing .faq-section .qna {
        width: 100%;
    }
}
@media (max-width: 500px) {
    .landing .faq-section {
        margin-top: 60px;
    }
    .landing .faq-section .title {
        margin-bottom: 30px;
    }
    .landing .faq-section .qna {
        margin-bottom: 30px;
    }
    .landing .faq-section .qna .que {
        margin-bottom: 15px;
    }
}

.footer-wrapper {
    background-color: var(--theme-color-2);
}
.footer-section {
    max-width: 1200px;
    margin: auto;
    padding: 15px;
    padding: 25px 0;
    background: var(--theme-color-2);
    color: var(--theme-color-1);
}
.footer-section .section-container .text-part {
    width: 100%;
    line-height: 27px;
}
.footer-section .section-container .text-part .text-wrap {
    display: flex;
}
.footer-section .section-container .text-part .text-wrap .item {
    flex: 1;
}
.footer-section .section-container .text-part .text-wrap .item .item-title {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
}
.footer-section .section-container .text-part .text-wrap .item .item-text {
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}
@media only screen and (max-width: 700px) {
    .footer-section .section-container .text-part .text-wrap .item {
        flex: unset;
    }
    .footer-section .section-container .text-part .text-wrap .item.explore,
    .footer-section .section-container .text-part .text-wrap .item.follow {
        width: 30%;
    }
    .footer-section .section-container .text-part .text-wrap .item.legal {
        width: 40%;
    }
}
@media only screen and (max-width: 450px) {
    .footer-section .section-container .text-part .text-wrap .item.explore{
        width: 30%;
    }
    .footer-section .section-container .text-part .text-wrap .item.legal {
        width: 50%;
    }
    .footer-section .section-container .text-part .text-wrap .item.follow {
        width: 20%;
    }
}

.accordion__button{
    box-sizing: border-box;
}